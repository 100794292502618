import React from 'react'
import styles from './hamburgercss.module.scss'

const HamburgerCSS = props => (
  <i
    className={props.toggleIcon ? styles.hamburger : styles.hamburgerX}
    role="button"
  >
    <span />
    <span />
    <span />
  </i>
)

export default HamburgerCSS
