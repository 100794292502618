import React from 'react'
import styles from './button.module.scss'

const Button = props => (
  <button onClick={props.onclick} className={styles.button}>
    {props.icon}
    {props.text ? <span className={styles.text}>{props.text}</span> : null}
  </button>
)

export default Button
