import React from 'react'
import Nav from '../components/Nav.js'
import styles from './navlayout.module.scss'
import Footer from './Footer'

export default ({ children }) => (
  <div>
    <Nav />
    <main className={styles.container}>{children}</main>
    <Footer />
  </div>
)

export const query = graphql`
  query {
    allStrapiContact {
      edges {
        node {
          id
          Email
        }
      }
    }
  }
`
