import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './socialcontacts.module.scss'
import FacebookIcon from '../components/icons/FacebookSVG.js'
import InstagramIcon from '../components/icons/InstagramSVG.js'

const SocialContacts = ({ data }) => {
  const { Instagram, Facebook } = data.allStrapiContact.edges[0].node

  return (
    <div className={styles.social}>
      {Instagram ? (
        <a
          className={styles.link}
          href={Instagram}
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
        >
          <InstagramIcon height="25px" width="25px" />
        </a>
      ) : null}
      {Facebook ? (
        <a
          className={styles.link}
          href={Facebook}
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <FacebookIcon height="25px" width="25px" />
        </a>
      ) : null}
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiContact {
          edges {
            node {
              id
              Facebook
              Instagram
            }
          }
        }
      }
    `}
    render={data => <SocialContacts data={data} {...props} />}
  />
)
