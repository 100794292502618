import React from 'react'
import styles from './pagetitle.module.scss'

const PageTitle = props => (
  <div className={styles.titleWrapper}>
    <h1 className={styles.title}>{props.title}</h1>
  </div>
)

export default PageTitle
