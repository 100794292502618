import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Hamburger from './icons/HamburgerCSS'
import Button from './Button'
// import Media from 'react-media'
import styles from './nav.module.scss'

class Nav extends React.Component {
  state = {
    contracted: true,
  }

  toggleContracted = () => {
    this.state.contracted
      ? this.setState({ contracted: false })
      : this.setState({ contracted: true })
  }

  render() {
    const collectionsData = this.props.data.collection.edges.map(
      collection => collection
    )

    const collections = collectionsData.sort((a, b) => {
      if (a.node.Priority < b.node.Priority) {
        return -1
      }

      if (a.node.Priority > b.node.Priority) {
        return 1
      }

      return 0
    })

    return (
      <nav className={styles.nav}>
        <span className={styles.toggleWrap}>
          <Button
            onclick={this.toggleContracted}
            // text="Menu"
            icon={<Hamburger toggleIcon={this.state.contracted} />}
          />
        </span>
        <div
          className={
            this.state.contracted ? styles.links : styles.expandedlinks
          }
        >
          <Link className={styles.link} activeClassName={styles.active} to="/">
            Home
          </Link>
          <Link
            className={styles.link}
            activeClassName={styles.active}
            to="/About/"
          >
            About the Artist
          </Link>
          <Link
            className={styles.link}
            activeClassName={styles.active}
            to="/Contact/"
          >
            Contact the Artist
          </Link>
          {collections.map(collection => (
            <Link
              key={collection.node.Title}
              className={styles.link}
              activeClassName={styles.active}
              to={`/${collection.node.Title.replace(/\s/g, '')}/`}
            >
              {collection.node.Title}
            </Link>
          ))}
          <Link
            className={styles.link}
            activeClassName={styles.active}
            to="/Galleries/"
          >
            Galleries
          </Link>
        </div>
      </nav>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        collection: allStrapiCollection {
          edges {
            node {
              Title
              Priority
            }
          }
        }
      }
    `}
    render={data => <Nav data={data} />}
  />
)
