import React from 'react'
import { Link, graphql } from 'gatsby'
import styles from './footer.module.scss'
import SocialContacts from './SocialContacts'
import EmailSVG from './icons/EmailSVG'

export default () => (
  <footer className={styles.footer}>
    <div className={styles.contacts}>
      <Link
        title="Go to contact page"
        to="/Contact/"
        className={styles.emaillink}
      >
        <EmailSVG />
      </Link>
      <SocialContacts />
    </div>

    <p className={styles.siteby}>Copyright &copy; {new Date().getFullYear()}</p>

    <p className={styles.siteby}>
      Website by{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.evan-harrison.com"
      >
        Evan
      </a>
    </p>
  </footer>
)

export const query = graphql`
  query {
    allStrapiContact {
      edges {
        node {
          id
          Email
        }
      }
    }
  }
`
