import React from 'react'
import { graphql } from 'gatsby'
import NavLayout from '../components/NavLayout.js'
import PageTitle from '../components/PageTitle.js'
import Img from 'gatsby-image'
import styles from './about.module.scss'

const About = ({ data }) => {
  const { About, Image } = data.allStrapiAbout.edges[0].node
  return (
    <NavLayout>
      <PageTitle title="Artist Statement" />
      <section className={styles.section}>
        <Img className={styles.img} fluid={Image.childImageSharp.fluid} />
        <p className={styles.aboutText}>{About}</p>
      </section>
    </NavLayout>
  )
}

export default About

export const query = graphql`
  query {
    allStrapiAbout {
      edges {
        node {
          About
          Image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
